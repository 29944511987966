import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../helper";

// default sharpen matrix:
// [
//   0, 1, 0,
//   1, 1, 1,
//   0, 1, 0,
// ]

export const initialState = {
  desiredGridSize: 10,
  threshold: 50,
  thresholdMin: null,
  thresholdMax: null,
  sharpenMatrixIndex: 0,
  invertImage: false,
  distribution: [],
  sortedValues: [],
  levels: [],
};

const setFilterOption = (state, action) => {
  return updateObject(state, {
    [action.field]: action.value,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_FILTER_OPTION:
      return setFilterOption(state, action);
    default:
      return state;
  }
};

export default reducer;
