import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";
import app, { initialState as appInitialState } from "./reducers/app";
import image, { initialState as imageInitialState } from "./reducers/image";
import grid, { initialState as gridInitialState } from "./reducers/grid";
import gridEnhanced, {
  initialState as gridEnhancedInitialState,
} from "./reducers/gridEnhanced";
import gridOptions, {
  initialState as gridOptionsInitialState,
} from "./reducers/gridOptions";
import history, {
  initialState as historyInitialState,
} from "./reducers/history";
import imageFilter, {
  initialState as imageFilterInitialState,
} from "./reducers/imageFilter";

export const getStore = (lang) => {
  const initialState = {
    app: appInitialState,
    image: imageInitialState,
    grid: gridInitialState,
    gridEnhanced: gridEnhancedInitialState,
    gridOptions: gridOptionsInitialState,
    history: historyInitialState,
    imageFilter: imageFilterInitialState,
  };
  const middleware = [thunk];
  const rootReducer = combineReducers({
    app: app,
    image: image,
    grid: grid,
    gridEnhanced: gridEnhanced,
    gridOptions: gridOptions,
    history: history,
    imageFilter: imageFilter,
  });
  return createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middleware))
  );
};
