// App action types
export const SET_PAGE = "SET_PAGE";
export const SAMPLE_TYPE = "SAMPLE_TYPE";
export const OPEN_SIDEBAR = "OPEN_SIDEBAR";
export const CLOSE_SIDEBAR = "CLOSE_SIDEBAR";
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";

// UI Progress action types
export const SET_VIEW = "SET_VIEW";

// Image actions
export const SET_IMAGE = "SET_IMAGE";

// grid actions
export const SET_GRID = "SET_GRID";
export const PAINT_CELL_TOGGLE = "PAINT_CELL_TOGGLE";
export const PAINT_CELL_VALUE = "PAINT_CELL_VALUE";
export const CLEAR_GRID = "CLEAR_GRID";

// grid clue actions
export const SET_X_AXIS_CLUES = "SET_X_AXIS_CLUES";
export const SET_Y_AXIS_CLUES = "SET_Y_AXIS_CLUES";
// export const SET_GRID_WITH_CLUES = "SET_GRID_WITH_CLUES";
export const SET_X_MAX_CLUES = "SET_X_MAX_CLUES";
export const SET_Y_MAX_CLUES = "SET_Y_MAX_CLUES";

// grid options actions
export const SET_GRID_OPTIONS = "SET_GRID_OPTIONS";

// history actions
export const APPEND_HISTORY = "APPEND_HISTORY";
export const UNDO = "UNDO";
export const REDO = "REDO";

// image filter actions
export const SET_FILTER_OPTION = "SET_FILTER_OPTION";