import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../helper";

export const initialState = {
  xClues: [],
  yClues: [],
  xClueMaxLength: null,
  yClueMaxLength: null,
};

const setXClues = (state, action) => {
  return updateObject(state, {
    xClues: action.xClues,
  });
};

const setYClues = (state, action) => {
  return updateObject(state, {
    yClues: action.yClues,
  });
};

const setXMaxClues = (state, action) => {
  return updateObject(state, {
    xClueMaxLength: action.xClueMaxLength,
  });
};

const setYMaxClues = (state, action) => {
  return updateObject(state, {
    yClueMaxLength: action.yClueMaxLength,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_X_AXIS_CLUES:
      return setXClues(state, action);
    case actionTypes.SET_Y_AXIS_CLUES:
      return setYClues(state, action);
    case actionTypes.SET_X_MAX_CLUES:
      return setXMaxClues(state, action);
    case actionTypes.SET_Y_MAX_CLUES:
      return setYMaxClues(state, action);
    default:
      return state;
  }
};

export default reducer;
