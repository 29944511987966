import React from "react";
import { Provider } from "react-redux";
import { getStore } from "./src/store/reduxWrapper";
import i18n from "./i18next";
import { I18nextProvider } from "react-i18next";
import { PageContextProvider } from "./pageContext";

const store = getStore();

export const wrapWithProvider = ({ element }) => (
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>{element}</Provider>
  </I18nextProvider>
);

export const wrapElementWithProvider = ({ element, props }) => {
  return (
    <PageContextProvider value={props.pageContext}>
      {element}
    </PageContextProvider>
  );
};
