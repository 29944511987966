import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../helper";

export const initialState = {
  image: "",
  fileName: null,
};

const setImage = (state, action) => {
  return updateObject(state, {
    image: action.image,
    fileName: action.fileName,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_IMAGE:
      return setImage(state, action);
    default:
      return state;
  }
};

export default reducer;
