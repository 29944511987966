import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../helper";

export const initialState = {
  snapToGrid: true,
  minorBorders: true,
  majorVerticalGridLines: true,
  majorHorizontalGridLines: true,
  verticalGridLinesBucket: 5,
  horizontalGridLinesBucket: 5,
  horizontalCluePosition: 0,
  verticalCluePosition: 0,
  clueColor: "#a8ccff",
  cornerColor: "#a8ccff",
  majorGridColor: "#000000",
  backgroundColor: "#ffffff",
  hideClues: true,
};

// creates a width x height sized matrix, initialized to 0
const setOptions = (state, action) => {
  return updateObject(state, action);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_GRID_OPTIONS:
      return setOptions(state, action);
    default:
      return state;
  }
};

export default reducer;
