import * as actionTypes from "../actions/actionTypes";
import { cell_values } from "../../constants/constants";
import { updateObject } from "../helper";

export const initialState = {
  gridWidth: 5,
  gridHeight: 5,
  grid: [...Array(5)].map((x) => Array(5).fill(cell_values.EMPTY)),
};

// clear grid with defaults
const clearGrid = (state, action) => {
  return updateObject(state, {
    grid: [...Array(state.gridHeight)].map((x) =>
      Array(state.gridWidth).fill(cell_values.EMPTY)
    ),
  });
};
// set grid dimensions, both height and width
const setGrid = (state, action) => {
  return updateObject(state, {
    grid: [...action.grid],
    gridWidth: action.gridWidth,
    gridHeight: action.gridHeight,
  });
};
const paintCell = (state, action) => {
  const newGrid = [...state.grid];
  newGrid[action.row][action.col] =
    newGrid[action.row][action.col] === cell_values.SQUARE
      ? cell_values.EMPTY
      : cell_values.SQUARE;
  return updateObject(state, {
    grid: newGrid,
  });
};
const paintCellValue = (state, action) => {
  const newGrid = [...state.grid];
  newGrid[action.row][action.col] = action.value;
  return updateObject(state, {
    grid: newGrid,
  });
};
const undo = (state, action) => {
  return updateObject(state, {
    gridWidth: action.gridWidth,
    gridHeight: action.gridHeight,
    grid: action.grid,
  });
};
const redo = (state, action) => {
  return updateObject(state, {
    gridWidth: action.gridWidth,
    gridHeight: action.gridHeight,
    grid: action.grid,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CLEAR_GRID:
      return clearGrid(state, action);
    case actionTypes.SET_GRID:
      return setGrid(state, action);
    case actionTypes.PAINT_CELL_VALUE:
      return paintCellValue(state, action);
    case actionTypes.PAINT_CELL_TOGGLE:
      return paintCell(state, action);
    case actionTypes.UNDO:
      return undo(state, action);
    case actionTypes.REDO:
      return redo(state, action);
    default:
      return state;
  }
};

export default reducer;
