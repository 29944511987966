// UI Progress State Enums
export const START = 0;
export const DRAWING = 1;
export const POST_PROCESSING = 2;
export const FINAL_TOUCHES = 3;

export const email = "pix-team@protonmail.com";

export const clueHorizontalPositions = [
  "left", "right", "both"
];

export const clueVerticalPositions = [
  "top", "bottom", "both"
];

export const CLUE_POSITION = {
  LEFT_ONLY: 0,
  RIGHT_ONLY: 1,
  BOTH_SIDES: 2,
  TOP_ONLY: 0,
  BOTTOM_ONLY: 1,
};
export const LEFT_ONLY = 0;
export const RIGHT_ONLY = 1;
export const BOTH_SIDES = 2;
export const TOP_ONLY = 0;
export const BOTTOM_ONLY = 1;

export const matrices = [
  [
    [1 / 8, 1 / 8, 1 / 8],
    [1 / 8, -1, 1 / 8],
    [1 / 8, 1 / 8, 1 / 8],
  ],
  [
    [1/2, 1/2, 1/2],
    [1/2, -1, 1/2],
    [1/2, 1/2, 1/2],
  ],
];

export const cell_values = {
  CORNER: -4,
  EMPTY_CLUE: -3,
  CLUE: -2,
  SQUARE: -1,
  EMPTY: 0,
}