import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../helper";

export const initialState = {
  history: [],
  historyIndex: 0,
};

const appendHistory = (state, action) => {
  return updateObject(state, {
    history: [
      ...state.history.slice(0, state.historyIndex + 1),
      action.timepoint,
    ],
    historyIndex: state.historyIndex + 1,
  });
};

const undo = (state, action) => {
  return updateObject(state, {
    historyIndex: state.historyIndex > 0 ? state.historyIndex - 1 : 0,
  });
};

const redo = (state, action) => {
  return updateObject(state, {
    historyIndex:
      state.historyIndex < state.history.length - 1
        ? state.historyIndex + 1
        : state.history.length - 1,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.APPEND_HISTORY:
      return appendHistory(state, action);
    case actionTypes.UNDO:
      return undo(state, action);
    case actionTypes.REDO:
      return redo(state, action);
    default:
      return state;
  }
};

export default reducer;
