import * as actionTypes from "../actions/actionTypes";
import { START } from "../../constants/constants";
import { updateObject } from "../helper";

export const initialState = {
  currentPage: START,
  lastPage: null,
  sidebarOpen: false,
  currentLang: null,
};

const openSidebar = (state, action) => {
  return updateObject(state, {
    sidebarOpen: true,
  });
};

const closeSidebar = (state, action) => {
  return updateObject(state, {
    sidebarOpen: false,
  });
};

const toggleSidebar = (state, action) => {
  return updateObject(state, {
    sidebarOpen: !state.sidebarOpen,
  });
};

const setProgress = (state, action) => {
  return updateObject(state, {
    currentPage: action.currentPage,
    lastPage: state.currentPage,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_VIEW:
      return setProgress(state, action);
    case actionTypes.OPEN_SIDEBAR:
      return openSidebar(state, action);
    case actionTypes.CLOSE_SIDEBAR:
      return closeSidebar(state, action);
    case actionTypes.TOGGLE_SIDEBAR:
      return toggleSidebar(state, action);
    default:
      return state;
  }
};

export default reducer;
